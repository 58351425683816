import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from './Loading';

const Dashboard = ({token}) => {
    const { user } = useAuth0();
    const [accounts, setAccounts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groupedRoles, setGroupedRoles] = useState({});
    const [loading, setLoading] = useState(false);

    const baseURL = "https://095uu10x5k.execute-api.us-east-1.amazonaws.com/production"

    useEffect(() => {
        setLoading(true);
        if (!token) {
            return;
        }

        fetch(`${baseURL}/users/${user.nickname}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then(response => {
            if(!response.ok) { throw response }
            return response.json()
        })
        .then(data => {
            setAccounts(data ? data : []);
            fetch(`${baseURL}/roles`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(roleResponse => {
                if(!roleResponse.ok) { throw roleResponse }
                return roleResponse.json();
            })
            .then(roleData => {
                const processedRoles = roleData.filter(role => role.status === "processed");
                setRoles(processedRoles);

                const roleGroups = processedRoles.reduce((acc, role) => {
                    const { account: { accountName } } = role;
                    if (!acc[accountName]) {
                        acc[accountName] = [];
                    }
                    acc[accountName].push(role);
                    return acc;
                }, {});
                
                setGroupedRoles(roleGroups);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setRoles([]);
                setLoading(false);
            });
        })
        .catch(error => {
            console.error('Error:', error)
            setAccounts([]);
            setLoading(false);
        });
    }, [token]);

    if (loading) {
        return <Loading />
    }

    return (
        <Container style={{ marginTop: '20px' }}>
            <h1 style={{ marginBottom: '20px' }}>Currently provisioned ephemeral IAM roles</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Role Name</th>
                        <th>Destination Policy Services</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(groupedRoles).map((accountName, i) =>
                        groupedRoles[accountName].map((role, index) => (
                            <tr key={index}>
                                <td>{accountName}</td>
                                <td>
                                    <Link to={`/roles/${role.id}`} state={{role: role}}>
                                        {role.roleName}
                                    </Link>
                                </td>
                                <td>
                                    {role.destinationPolicyServices.map(service => (
                                        <div key={service}>
                                            <span style={{color: 'red', backgroundColor: '#f8f8f8', fontFamily: 'Courier, monospace', padding: '2px 5px', borderRadius: '3px'}}>
                                                {service}
                                            </span>
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <h1 style={{ marginTop: '20px', marginBottom: '20px' }}>Accounts</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Account Id</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.length > 0 ? (
                        accounts.map((account, index) => (
                            <tr key={index}>
                                <td>
                                    <Link to={`/accounts/${account.id}`} state={{name: account.accountName}}>
                                        {account.accountName}
                                    </Link>
                                </td>
                                <td>{account.id}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">You do not have access to any accounts that have the necessary JIT role</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
}

export default Dashboard;