import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Header = () => {
    const { user } = useAuth0();
  
    return (
      <Navbar bg="dark" variant="dark" expand="lg" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Container>
        <Navbar.Brand>Signed in as {user.nickname}</Navbar.Brand>
        <Navbar.Text style={{fontSize: '1.5em' }}>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>JIT IAM</Link>
        </Navbar.Text>
        <LogoutButton />
        </Container>
      </Navbar>
    );
}
  
export default Header