import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider 
      domain='https://redventures-prod.auth0.com'
      clientId='v6pQcEwuYUKTVhQbZmHP7Gn6Y4FLqXBV'
      authorizationParams={
        {
          redirect_uri: window.location.origin,
          audience: 'production-just-in-time-iam-api-resource',
          // scope: 'read:current_user'
        }
      }
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);