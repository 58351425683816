import { useAuth0 } from '@auth0/auth0-react';
import {Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './components/Loading';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import AccountPage from './components/AccountPage';
import RolePage from './components/RolePage';
import { useState, useEffect } from 'react';

const HomePage = ({token}) => {
  return (
    <div>
      <Header />
      <Dashboard token={token} />
    </div>
  )
}

const App = () => {
  const {isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getAccessToken = async () => {
      const authAccessToken = await getAccessTokenSilently();
      if (authAccessToken) {
        setAccessToken(authAccessToken);
      }
    }

    if (isAuthenticated) {
      getAccessToken();
    }

  }, [isAuthenticated]);

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loading />
  }

  return (
    <Routes>
      <Route exact path="/" element={<HomePage token={accessToken} />} />
      <Route path="/accounts/:id" element={<AccountPage token={accessToken} />} />
      <Route path="/roles/:id" element={<RolePage token={accessToken} />} />
    </Routes>
  )
}
export default App;
