import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem' }}>
                <span className="sr-only"></span>
            </Spinner>
        </div>
    );
}

export default Loading;
