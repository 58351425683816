import React, { useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, ListGroup, Table, Alert, Button } from 'react-bootstrap'
import { generate } from 'random-words';
import Header from './Header';

const RolePage = ({token}) => {
    const { user } = useAuth0();
    let location = useLocation();
    const navigate = useNavigate();

    let role = location.state.role;

    const baseURL = "https://095uu10x5k.execute-api.us-east-1.amazonaws.com/production"

    const roleWords = generate({ exactly: 3, minLength: 5 });
    const sourceRoleProfileName = roleWords.slice(0,2).join('-');
    const ephemeralRoleProfileName = roleWords.join('-');

    const switchRoleUrl = `https://signin.aws.amazon.com/switchrole?roleName=${role.roleName}&account=${role.account.accountName}`;

    const cliCommands = `
# source role
[profile ${sourceRoleProfileName}]
sso_start_url = https://rv.awsapps.com/start
sso_region = us-east-1
region = us-east-1
output = json
sso_account_id=${role.account.id}
sso_role_name=${role.sourceRoleName}
duration_seconds=3600

# ephemeral role
[profile ${ephemeralRoleProfileName}]
role_arn=${role.roleARN}
source_profile=${sourceRoleProfileName}
role_session_name=${user.nickname}
region=us-east-1
output=json
`;

    const handleCopy = () => {
        navigator.clipboard.writeText(cliCommands);
    }

    const handleDelete = () => {
        fetch(`${baseURL}/role/${role.id}`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error('Something went wrong');
            }
        })
        .then(data => {
            alert('Role deleted successfully');
            navigate('/')
        })
        .catch((error) => {
            alert(`Failed to delete role: ${error.message}`);
        });
    }

    return (
        <div>
        <Header />
        <Container style={{ marginTop: '20px' }}>
            <Alert variant="warning">
                Warning: This role will be deleted 2 hours after it is last used.
            </Alert>
            <h1>{role.account.accountName} - {role.account.id}</h1>
            <ListGroup variant="flush">
                <ListGroup.Item>Source Role Name: {role.sourceRoleName}</ListGroup.Item>
                <ListGroup.Item>Role Name: {role.roleName}</ListGroup.Item>
                <ListGroup.Item>Role ARN: {role.roleARN}</ListGroup.Item>
            </ListGroup>
            <h1 style={{ marginTop: '20px' }}>Selected Services</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Service</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        role.destinationPolicyServices.map((service, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{service}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <h1 style={{ marginTop: '20px' }}>To assume using the AWS CLI</h1>
            <div style={{backgroundColor: '#f8f9fa', padding: '10px', border: '1px solid #dee2e6', borderRadius: '5px'}}>
                <pre style={{margin: '0'}}>{cliCommands}</pre>
            </div>
            <Button onClick={handleCopy} variant="primary" style={{ marginTop: '10px' }}>Copy to Clipboard</Button>
            <h1 style={{ marginTop: '20px' }}>To assume via link</h1>
            <Alert variant="warning" style={{ marginTop: '20px' }}>
                Note: You must be already signed in to the account with the correct source role for the link to work.
            </Alert>
            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td>Switch Role URL</td>
                        <td>
                            <a href={switchRoleUrl} target="_blank" rel="noopener noreferrer">{switchRoleUrl}</a>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Button onClick={handleDelete} variant="danger" style={{ marginTop: '10px' }}>Delete Role Now</Button>
        </Container>
        </div>
    )
}

export default RolePage
