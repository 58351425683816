import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';

const LogoutButton = () => {
  const { logout } = useAuth0();         
  return (
    <Button variant="outline-light" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log out</Button>
  )
}

export default LogoutButton