import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Form, Button, ListGroup, Badge, Row, Col } from 'react-bootstrap'; // Import Row, Col
import Loading from './Loading';
import Header from './Header';

const AccountPage = ({ token }) => {
    let { id } = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    let name = location.state.name;
    const { user } = useAuth0();
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [searchText, setSearchText] = useState("");
    const [roles, setRoles] = useState([]);
    const [services, setServices] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [loadingServices, setLoadingServices] = useState(false);
    const [createRoleStatus, setCreateRoleStatus] = useState("");
    const [creationReason, setCreationReason] = useState("");


    const baseURL = "https://095uu10x5k.execute-api.us-east-1.amazonaws.com/production"

    const jitIamRole = {
        username: user.nickname,
        account: { id, accountName: name },
        sourceRoleName: selectedRole,
        destinationPolicyServices: selectedServices.map(s => s.Prefix),
        status: "queued",
        creationReason: creationReason
    };


    useEffect(() => {
        setLoadingRoles(true);
        if (!token) {
            return;
        }
        fetch(`${baseURL}/users/${user.nickname}/accounts/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setRoles(data);
                setLoadingRoles(false);
            });

        setLoadingServices(true);
        fetch(`${baseURL}/policies`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setServices(data);
                setLoadingServices(false);
            });
    }, [token]);


    const handleServiceSelect = (service) => {
        if (selectedServices.length < 5 && !selectedServices.some(s => s.Prefix === service.Prefix)) {
            setSelectedServices(prevServices => [...prevServices, service]);
        }
    };

    const handleServiceUnselect = (service) => {
        setSelectedServices(prevServices => prevServices.filter(s => s.Prefix !== service.Prefix));
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    }

    const handleCreateRole = () => {
        setCreateRoleStatus("loading"); // Set the status to loading
        fetch(`${baseURL}/createrole`, {
            method: 'POST',
            headers: {
                "Content-Type": `application/json`,
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(jitIamRole),
        })
            .then(response => response.json())
            .then(data => {
                let id = data.id;
                let timeout = Date.now() + 10 * 60 * 1000; // 10 minutes from now

                const interval = setInterval(() => {
                    if (Date.now() > timeout) {
                        clearInterval(interval);
                    } else {
                        fetch(`${baseURL}/role/${id}`, {
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                if (data.status === 'failed') {
                                    setCreateRoleStatus("failed"); // Set the status to failed
                                    window.alert(`Error: failed to provision role: ${data.errorMessage}`); // Show an alert
                                    clearInterval(interval); // stop the interval
                                }
                                if (data.status === 'processed') {
                                    setCreateRoleStatus("processed"); // Set the status to processed
                                    clearInterval(interval); // stop the interval
                                    navigate(`/roles/${data.id}`, { state: { role: data } });
                                }
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                            });
                    }
                }, 10000); // 10 seconds interval
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const filteredServices = services.filter(service =>
        service.Prefix.toLowerCase().includes(searchText.toLowerCase())
    );

    const isButtonDisabled = selectedRole === "" || selectedServices.length === 0 || creationReason === "";

    if (createRoleStatus === "loading") {
        return <Loading />
    } else {
        return (
            <div>
                <Header />
                <Container style={{ marginTop: '30px' }}>
                    <h1>Account: {name} ({id})</h1>
                    <h3 style={{ marginTop: '30px' }}>Source Role</h3>
                    {loadingRoles ? (
                        <Loading />
                    ) : (
                        <ListGroup>
                            {roles.map((role, index) => (
                                <ListGroup.Item key={index} onClick={() => setSelectedRole(role)}>
                                    <Row>
                                        <Col xs={1}>
                                            <Form.Check
                                                type="radio"
                                                name="roleRadio"
                                                value={role}
                                                checked={selectedRole === role}
                                                readOnly
                                            />
                                        </Col>
                                        <Col>
                                            {role}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                    <h3 style={{ marginTop: '30px' }}>Destination Policy</h3>
                    <p>This policy will be attached to the ephemeral role. You can select up to 5 services.</p>
                    <Form.Control
                        type="search"
                        placeholder="Search for AWS services"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <Row style={{ marginTop: '30px' }}>
                        <Col><h4>Available Services</h4></Col>
                        <Col><h4>Selected Services</h4></Col>
                    </Row>
                    <Row>
                        <Col>
                            {loadingServices ? (
                                <Loading />
                            ) : (
                                <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                                    <ListGroup>
                                        {filteredServices.map((service, index) => (
                                            <ListGroup.Item key={index} action onClick={() => handleServiceSelect(service)}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>{service.Name}</span>
                                                    <span style={{ color: 'red', backgroundColor: '#f8f8f8', fontFamily: 'Courier, monospace', padding: '2px 5px', borderRadius: '3px' }}>{service.Prefix}</span>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>
                            )}
                        </Col>
                        <Col>
                            <ListGroup>
                                {selectedServices.map((service, index) => (
                                    <ListGroup.Item key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{service.Name}</span>
                                        <Button variant="danger" size="sm" onClick={() => handleServiceUnselect(service)}>
                                            Deselect
                                        </Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Form.Group style={{ marginTop: '30px' }}>
                        <Form.Label>Reasoning for creating role</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter the reason for creating this role"
                            value={creationReason}
                            onChange={e => setCreationReason(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" style={{ marginTop: '30px' }} onClick={handleCreateRole} disabled={isButtonDisabled}>Create Role</Button>
                </Container>
            </div>
        );
    }
}

export default AccountPage;
